// @ts-nocheck
import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import EditStrategyEditor from '../../components/ContentAdmin/StrategyTab/EditStrategyEditor'
import { CONTENT_ADMIN_ROUTES, ROUTES } from '../../constant'
import { Router } from '@reach/router'
import AdminLayout from '../../components/ContentAdmin'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    button: {
      marginTop: theme.spacing(3),
    },
    opinion: {
      width: theme.spacing(28),
    },
  })
)

export default () => {
  const classes = useStyles()

  return (
    <AdminLayout>
      <SEO title="Home" />
      <div className={classes.root}>
        <Router>
          <EditStrategyEditor path={`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY_EDIT}/:slug`} />
        </Router>
      </div>
    </AdminLayout>
  )
}
