import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles, Theme } from '@material-ui/core'
import CONF from '../../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      marginTop: theme.spacing(3),
    },
    root: {
      // top: theme.spacing(5),
      // left: theme.spacing(75),
      // width: theme.spacing(40),
      // height: theme.spacing(8),
      // backgroundColor: '#f77b18',
      marginBottom: theme.spacing(2),
    },
    profit: {
      // width: theme.spacing(40),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    drawdown: {
      width: theme.spacing(40),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    gridList: {
      width: '100%',
    },
    category: {
      width: theme.spacing(28),
      marginBottom: theme.spacing(2),
    },
    previewDiv: {
      // width: theme.spacing(50)
      marginBottom: theme.spacing(7),
    },
    opinion: {
      width: theme.spacing(28),
    },
    quillArea: {
      marginBottom: theme.spacing(10),
    },
    publishButton: {
      marginLeft: theme.spacing(3),
      // top: theme.spacing(5),
      // left: theme.spacing(75),
      width: theme.spacing(30),
      height: theme.spacing(6),
      // backgroundColor: '#f77b18',
    },
    deleteButton: {
      marginLeft: theme.spacing(3),
      // top: theme.spacing(5),
      // left: theme.spacing(75),
      width: theme.spacing(10),
      height: theme.spacing(6),
      backgroundColor: CONF.css.red,
    },
  })
)
