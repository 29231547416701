import React, { useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button, InputLabel, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import { useStyles } from './style'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import { GalleryAction, GalleryState, StrategyAction, StrategyState } from '../../../Strategy/state'
import { CONTENT_ADMIN_ROUTES, ROUTES, STRATEGY_TYPE_ENUM } from '../../../../constant'
import { useState } from '@hookstate/core'
import { getObj } from '../../../../utils/hookState'
import Divider from '@material-ui/core/Divider'
import Slider from 'react-slick'
import { IGalleryState } from '../../../Strategy/interface'
import GridList from '@material-ui/core/GridList'

export default function EditStrategyEditor({ slug }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const oneState = useState(StrategyState.one)
  const galleryListState = useState<string>(GalleryState.list)

  const [name, setTitleEn] = React.useState('')
  const [titleVi, setTitleVi] = React.useState('')
  const [description, setDescriptionEn] = React.useState('')
  const [descriptionVi, setDescriptionVi] = React.useState('')
  const [strategyType, setStrategyType] = React.useState('')
  const [expectedProfit, setExpectedProfit] = React.useState(0)
  const [expectedMaxDrawdown, setExpectedMaxDrawdown] = React.useState(0)
  // const [fileState, setFileState] = React.useState({ file: '', imagePreviewUrl: '' })
  const [imageListState, setImageListState] = React.useState<IGalleryState[]>([])
  const [time, setTime] = React.useState(+new Date())

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // cssEase: "linear"
  }

  const handleClickPublish = () => {
    StrategyAction.update(
      slug,
      name,
      titleVi,
      description,
      descriptionVi,
      strategyType,
      expectedProfit,
      expectedMaxDrawdown
    )()
    navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY}`)
  }

  const handleUpdateGallery = () => {
    GalleryAction.update(slug, imageListState)()
    navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY}`)
  }

  const handleRevertImageUpload = () => {
    setImageListState(getObj(galleryListState))
  }

  const handleClickDelete = () => {
    StrategyAction.deleteZ(slug)
    navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY}`)
  }

  const handleImageChange = (e) => {
    e.preventDefault()

    for (const file of e.target.files) {
      // let img = new Image()
      // img.src = URL.createObjectURL(file)
      // img.title = file.name

      let reader = new FileReader()
      reader.onloadend = () => {
        const fileNameList = imageListState.map((record) => record.name)
        if (!fileNameList.includes(file.name)) {
          // @ts-ignore
          imageListState.push({ name: file.name, data: reader.result })
        }
      }

      reader.readAsDataURL(file)
    }
    setImageListState(imageListState)
    setTimeout(function () {
      setTime(+new Date())
    }, 500)
  }

  const handleRemoveImage = (imageName) => {
    setImageListState(imageListState.filter((image) => image.name !== imageName))
  }

  useEffect(() => {
    StrategyAction.findOne(slug)().then(() => {
      setTitleEn(getObj(oneState).name)
      setTitleVi(getObj(oneState).titleVi)
      setDescriptionEn(getObj(oneState).description)
      setDescriptionVi(getObj(oneState).descriptionVi)
      setStrategyType(getObj(oneState).type)
      setExpectedProfit(getObj(oneState).expected_profit)
      setExpectedMaxDrawdown(getObj(oneState).expected_max_drawdown)
    })
  }, [])

  useEffect(() => {
    GalleryAction.find(slug)().then(() => {
      setImageListState(getObj(galleryListState))
    })
  }, [])

  return (
    <>
      <Grid key={time} className={classes.div} container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.root}
            fullWidth
            label={'Title English'}
            value={name}
            onChange={(e) => setTitleEn(e.target.value)}
            variant="outlined"
          />
          <TextField
            className={classes.root}
            fullWidth
            multiline
            label={'Description English'}
            value={description}
            onChange={(e) => setDescriptionEn(e.target.value)}
            variant="outlined"
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            className={classes.root}
            fullWidth
            label={'Title Vietnamese'}
            value={titleVi}
            onChange={(e) => setTitleVi(e.target.value)}
            variant="outlined"
          />

          <TextField
            className={classes.root}
            fullWidth
            multiline
            label={'Description Vietnamese'}
            value={descriptionVi}
            onChange={(e) => setDescriptionVi(e.target.value)}
            variant="outlined"
            rows={4}
          />
        </Grid>
        <Grid container item xs={12} md={12} justify={'space-between'}>
          <FormControl variant="outlined">
            <InputLabel>Type</InputLabel>
            <Select
              className={classes.category}
              value={strategyType}
              onChange={(e) => setStrategyType(e.target.value as string)}
              label="Type"
              disabled
            >
              {STRATEGY_TYPE_ENUM.map((record) => (
                <MenuItem key={record} value={record}>
                  {t(record)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            className={classes.profit}
            type="number"
            label={'Expected Profit'}
            value={expectedProfit}
            onChange={(e) => setExpectedProfit(+e.target.value)}
            variant="outlined"
          />

          <TextField
            className={classes.drawdown}
            type="number"
            label={`Expected Max Drawdown (without '-')`}
            value={expectedMaxDrawdown}
            onChange={(e) => setExpectedMaxDrawdown(+e.target.value)}
            variant="outlined"
          />

          <Button className={classes.publishButton} variant="contained" color="primary" onClick={handleClickPublish}>
            Update Info
          </Button>
          {/*<Button*/}
          {/*  className={classes.deleteButton}*/}
          {/*  variant="contained"*/}
          {/*  onClick={handleClickDelete}*/}
          {/*>*/}
          {/*  Delete*/}
          {/*</Button>*/}
        </Grid>
      </Grid>
      <Divider />
      <Grid className={classes.div} container spacing={3}>
        <Grid item xs={12}>
          <input type="file" multiple onChange={handleImageChange} />
          <Button onClick={handleRevertImageUpload}>Revert</Button>
          <Button onClick={handleUpdateGallery}>Save change</Button>
        </Grid>
      </Grid>
      <GridList cellHeight={380} className={classes.gridList} cols={4}>
        {imageListState.map((fileDetail) => (
          <div className={classes.previewDiv} key={fileDetail.name}>
            <Button onClick={() => handleRemoveImage(fileDetail.name)}>Remove</Button>
            <img src={fileDetail.data} alt={fileDetail.name} />
          </div>
        ))}
      </GridList>
      <div style={{ height: 1000 }} />
      <Button
        className={classes.deleteButton}
        variant="contained"
        onClick={handleClickDelete}
      >
        Delete
      </Button>
    </>
  )
}
